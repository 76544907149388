
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import {
  apiGameBlackWithdrawalAccountLists,
  apiGameBlackWithdrawalAccountDelete,
  apiGameBlackWithdrawalAccountIsShow
} from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import GameBlackWithdrawalAccountEdit from '@/components/game-black-withdrawal-account-edit.vue'

@Component({
    components: {
      GameBlackWithdrawalAccountEdit,
        LsDialog,
        LsPagination,
        ExportData,
    }
})
export default class GameBlackIpManage extends Vue {
    /** S Data **/

    // 分页
    pager: RequestPaging = new RequestPaging()

    remarks = '' //拉黑备注
    dialogVisible = true
    /** E Data **/

    tableData = []

    pickerOptions = {
      shortcuts: [
        {
          text: '最近一周',
          onClick(picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近一个月',
          onClick(picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近三个月',
          onClick(picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ]
    }

    /** S Methods **/

    // 顶部查询表单
    form = {
      account: '',
      realname: '',
      end_time: '',
      start_time: '',
    }

    splitTime() {
      if (this.tableData != null) {
        this.form.start_time = this.tableData[0]
        this.form.end_time = this.tableData[1]
      }
    }


  // 更改显示开关状态
  changeStatus(value: 0 | 1, row: any) {
    apiGameBlackWithdrawalAccountIsShow({
      id: row.id,
    })
        .then(() => {
          this.getList()
        })
        .catch((err: any) => {
          console.log('err', err)
        })
  }


    // 重置
    onReset() {
      this.form = {
        account: '',
        realname: '',
        end_time: '',
        start_time: '',
      }
      this.tableData = []

      this.getList()
    }

    // 获取分类列表
    getList() {
        this.pager
            .request({
                callback: apiGameBlackWithdrawalAccountLists,
                params: {
                  ...this.form
                    //aid: this.currentAppId,
                },
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除该分类
    onDel(row: any) {
      apiGameBlackWithdrawalAccountDelete({
            id: row.id,
            //aid: this.currentAppId,
        }).then(() => {
            this.getList()
        })
    }

  handleBeforeClose(act: any) {
      return false;
  }

    /** E Methods **/

    /** S Life Cycle **/
    async created() {
        //await this.getGameAppList()
        this.loadData()
    }
    /** E Life Cycle **/

    loadData() {
        return Promise.all([
            this.getList(),
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }
}
